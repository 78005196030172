<template>
  <div
    v-if="spots && $isNotNullOrUndefined(spots) && spots.length > 0"
    class="o-spots-dynamic"
  >
    <transition-group
      v-if="isLoading"
      appear
      name="slide-down"
      tag="div"
      :duration="1000"
    >
      <div
        v-for="(n, index) in 2"
        :key="`skeleton-${index}`"
        class="col-12 col-md-6 col-lg-4"
      >
        <skeleton-loader
          class="m-5"
          layout="card"
          :show-loader="true"
          :aspect-ratio="442/433"
        />
      </div>
    </transition-group>
    <component
      :is="content.nzSettingLayout === 'List' ? 'spots-list' : 'spots-carousel'"
      v-else
      :key="content.key + pageIndex"
      :is-first="isFirst"
      :spots="spotsToComponent"
      :tags="content.nzSettingShowFilter ? tags : []"
      :selected="selected"
      :headline="content.headline"
      :image="content.image"
      :image-alt-text="content.imageAltText"
      :is-aligned-right="content.nzSettingLayout === 'Carousel - Image right'"
      @onTagSelected="onTagSelected"
      @onTagClear="onTagClear"
    />
    <div
      v-if="!$isNotNullOrUndefined(content.bottomLink) && content.nzSettingLayout === 'List'"
      class="row"
    >
      <div class="col-12 d-flex justify-content-center mb-4">
        <list-progress :loading="false" :list-progress="listProgress" />
      </div>
      <div
        v-if="spotsToShow < spots.length || spots.length > spotsToShow"
        class="col-12 d-flex justify-content-center"
      >
        <load-more 
          :page="pageIndex + 1" 
          @load-more="loadMore"
        >
          <base-button id="load-more" class="-primary -wide">
            <span
              v-if="$isNotNullOrUndefined(content.showMore)"
            >
              {{ content.showMore.replace('[SPOTCOUNT]', spotsCount) }}
            </span>
            <span v-else>
              {{ $t("loadMoreResults", "Vis flere") }}
            </span>
          </base-button>
        </load-more>
      </div>
    </div>
    <div
      v-else-if="$isNotNullOrUndefined(content.bottomLink) && content.nzSettingLayout === 'List'"
      class="container"
    >
      <div class="row">
        <div class="col-12 d-flex pt-5 justify-content-end">
          <base-link
            :link="content.bottomLink.url"
            :target="content.bottomLink.target"
            class="-red -with-border text-decoration-none"
          >
            {{ content.bottomLink.name }}
          </base-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSpots } from '@/utils/spots';
import SkeletonLoader from '../atoms/SkeletonLoader.vue';
import BaseButton from '@/components/atoms/BaseButton';
import SpotsCarousel from '@/components/molecules/SpotsCarousel';
import SpotsList from '@/components/molecules/SpotsList';
import ListProgress from '@/components/atoms/ListProgress';
import BaseLink from '@/components/atoms/BaseLink.vue';
import contentMixin from '@/mixins/contentMixin';
import LoadMore from '@/components/molecules/ShopCommerce/LoadMore.vue'

import {
  mapMobileObserver,
  unMapMobileObserver
} from '@/utils/mobile-observer';

export default {
  name: 'SpotsDynamic',
  components: {
    SkeletonLoader,
    BaseButton,
    ListProgress,
    BaseLink,
    SpotsCarousel,
    SpotsList,
    LoadMore
  },
  mixins: [contentMixin],
  props: {
    isFirst: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      spotsToShow: 6,
      spotsCount: 6,
      windowPosition: 0,
      pageIndex: 1,
      selected: [],
      spotsAll: []
    };
  },
  computed: {
    ...mapMobileObserver(),
    spots () {
      let spots = this.spotsAll;
      return this.selected.length ? spots.filter(spot => this.selected.some(t => spot.tags.includes(t.value))) : spots
    },
    spotsToComponent () {
      return this.spots.slice(0, this.spotsToShow);
    },
    listProgress () {
      return { totalCount: this.spots.length, viewedItems: this.spotsToShow };
    },
    useQuery () {
      return this.content?.nzSettingUseQuery || false;
    },
    isLoading () {
      return this.$store.getters['content/content/isLoading'];
    },
    tags () {
      let spots = this.spotsAll;
      let tags = spots.filter(s => s.tags && s.tags.length).map(spot => {
        let tagsMapped = spot.tags?.map(tag => {
          return {
            key: spot?.key,
            value: tag
          }
        })
        return [].concat(...tagsMapped);
      });
      let flatten = [].concat(...tags);

      const unique = [...new Map(flatten.map(item => [item['value'], item])).values()]
      return unique;
    },
  },
  watch: {
    spots: function () {
      let maxCount = this.content.nzSettingMaxCount || 0;
      let totalCount = this.spots.length;
      let defaultCount = maxCount === 0 ? (this.spotsToShow * this.pageIndex) : maxCount
      this.spotsToShow = defaultCount >= totalCount ? totalCount : defaultCount;
      this.spotsCount = defaultCount >= totalCount ? totalCount : defaultCount;
    }
  },
  beforeDestroy () {
    unMapMobileObserver();
  },
  async mounted () {
    if (this.content?.pageTypes?.length) {
      this.spotsAll = await this.getSpots({
        parentId: this.content?.nzSettingSpotsParentPage ? this.content?.nzSettingSpotsParentPage.meta.id : this.$store.getters['content/content/getPageUid'],
        culture: this.$store.getters['site/getCurrentCulture'],
        recursive: !!this.content?.nzSettingIncludeDescendants,
        pageTypes: this.content?.pageTypes,
        sortOrder: this.content?.nzSettingSortOrder,
        orderByDescending: this.content?.nzSettingOrderBy === 'Descending' ? true : false,
      });
    }

    if (this.useQuery) {
      if (this.$isNotNullOrUndefined(window.location.search)) {
        let windowSearchQuery = window.location.search
          .replace('?', '')
          .split('&')
          .filter(v => v)
          .map(s => {
              s = s.replace('+', '%20');
              s = s.split('=').map(s => decodeURIComponent(s));
              return {
                  name: s[0],
                  value: s[1]
              };
          });

        windowSearchQuery.forEach(el => {
          if (el.name == 'page') {
            // convert string to number
            this.pageIndex =   Number(el.value);
            this.spotsToShow = this.spotsCount * el.value;
          }
          else if (el.name == 'position') {
            this.windowPosition = el.value;
          }
        });
      }
    }
    
  },
  updated () {
    this.$nextTick(() => {
      if (document.documentElement.scrollTop < this.windowPosition) {
        window.scrollTo(0, this.windowPosition );
      }
    })
  },
  methods: {
    getSpots,
    onTagSelected (item) {
      if (this.selected.some(s => s.key === item.key)) {
        this.selected = this.selected.filter(s => s.key !== item.key);
      } else {
        this.selected.push(item)
      }
    },
    onTagClear () {
      this.selected = [];
    },
    loadMore () {
      let currSpotsLength = this.spotsToShow;
      let incrementSpots = (this.content.nzSettingMaxCount) ? this.content.nzSettingMaxCount : 6;
      let initialScrollTop = document.documentElement.scrollTop ;

      if ( (currSpotsLength + incrementSpots) < this.spots.length) {
        this.spotsToShow += incrementSpots;
      }
      else {
        this.spotsToShow += (this.spots.length - currSpotsLength);
      }

      if ( (this.spotsToShow + incrementSpots) < this.spots.length) {
        this.spotsCount = incrementSpots;
      }
      else {
        this.spotsCount = (this.spots.length - this.spotsToShow);
      }

      /*
        this.$nextTick(function () { 
          window.scrollTo(0, initialScrollTop );
        });
      */

      this.pageIndex++;

      if (this.useQuery) {
        history.pushState(null, null, `${window.location.pathname}?page=${this.pageIndex}&position=${initialScrollTop}`);
      }
    }
  }
};
</script>
